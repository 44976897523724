import React from "react";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";

const Home = () => (
	<div className="hero bg-base-200 min-h-screen">
		<div className="hero-content text-center">
			<div className="max-w-md">
				<img src={logo} alt="Logo" />
				<h1 className="text-5xl font-bold">aiplz</h1>
				<p className="py-6">Something is baking...</p>
			</div>
		</div>
	</div>
);
//const About = () => <div className="text-center p-4">About Page</div>;

const App: React.FC = () => {
	return (
		<Router>
			<div className="min-h-screen bg-base-100 flex flex-col items-center w-full">
				{/* Navbar */}
				<div className="navbar bg-base-100">
					<div className="flex-1">
						<Link to="/" className="btn btn-ghost normal-case text-xl">
							aiplz
						</Link>
					</div>
					{/* <div className="flex-none">
						<ul className="menu menu-horizontal px-1">
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/about">About</Link>
							</li>
						</ul>
					</div> */}
				</div>
				{/* Main content */}
				<div className="flex-grow w-full">
					<Routes>
						<Route path="/" element={<Home />} />
						{/*<Route path="/about" element={<About />} />*/}
					</Routes>
				</div>
				{/* Footer */}
				<footer className="footer items-center p-4 bg-base-200 text-base-content mt-auto">
					<div className="items-center grid-flow-col">
						<p>© 2024 aiplz. All rights reserved.</p>
					</div>
				</footer>
			</div>
		</Router>
	);
};

export default App;
